<template>
    <div class="carrot-layer-bg">
      <div class="carrot-layer w-900px">
            <div class="dp-table w-100per">
                <p class="title float-left">업무처리자 선택</p>
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <div class="dp-table w-100per h-810px">
                  <div class="mt-40 clear">
                      <carrot-dept v-model="pop.idx_office" class="float-left w-160px" ></carrot-dept>
                      <carrot-team :idx_office="pop.idx_office" v-model="pop.idx_team" class="float-left w-120px ml-5"></carrot-team>
                      <button @click="pop.doSearch" class="btn-default float-left ml-5">검색</button>
                  </div>
                    <div class="w-100per h-210px ver-top">
                        <div class="dp-table w-100per">
                            <div class="dp-tablecell" >
                              <table class="table-col mt-10" >
                                <thead style="display: block; width:858px;border-top: 0px ">
                                <tr>
                                  <th width=58>No</th>
                                  <th width="260">본부</th>
                                  <th width="260">팀</th>
                                  <th width="260">이름</th>
                                </tr>
                                </thead>
                                <tbody style="display: block; width:858px; height: 250px;overflow: auto;">
                                <tr v-for="(irow, i) in pop.in_staff" :key="i"  >
                                  <td width="58">
                                    <input type="checkbox"  @click="pop.setLine(irow)" v-model="irow.checked" :value="irow.idx">
                                  </td>
                                  <td width="260">{{ irow.office_name }}</td>
                                  <td width="260">{{ irow.team_name }}</td>
                                  <td width="260">{{ irow.ename }}({{ irow.kname }})</td>
                                </tr>
                                <tr v-if="pop.total==0">
                                  <td colspan="4">본부/팀에 속한 재직자가 없습니다.</td>
                                </tr>
                                </tbody>
                              </table>
                                <div class="dp-table w-100per mt-30 ">
                                    <span class="block-stitle float-left mt-5">업무처리자</span>
                                </div>
                                <div class="borderDDD-box mt-10 h-165px overhidden overscroll">
                                    <div class="selList-box">
                                        <div class="selbox" v-for="(staff, idx) in pop.line_staff" :key="staff.idx">
                                            <label class="label-sel"><span class="ml-5 mr-20"> {{staff.kname}} ({{staff.ename}})</span></label>
                                            <button class="btn-del" @click="pop.delLineStaff(idx)">X</button>
                                        </div>
                                    </div>
                                </div>
                              <div class="layer-btn mt-10 w-100per dp-table txt-center">
                                <button class="btn-default dp-inblock ml-20" @click="pop.sendStaff()">확인</button>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotDept from '@/components/common/CarrotDept.vue'
import CarrotTeam from '@/components/common/CarrotTeam.vue'
import { useStore } from 'vuex'

export default {
    name: 'ApprovalLinePopup',
    props: {
        idx: {
            type: Number,
            default: 0,
        }
    },
    components: {
        CarrotDept,
        CarrotTeam,
    },

    setup(props, {emit}) {
        const toast = useToast()
        const store = useStore()

        const pop = reactive({
            teams : [],
            staff : [],
            in_staff : [],
            line_staff : [],
            cc_staff: [],
            line_title: "",
            idx_team: 0,
            idx_office : 0,
            idx_admin: 0,
            active_office : 0,
            find_name:"",
            deptname: "",
            showApprovalLineLoad: false,
            checkAll: false,
            doCheckAll : () => {
                let val = false;
                if(pop.checkAll == true) {
                    val = true;
                }
                for(let i=0;i<pop.in_staff.length;i++) {
                    pop.in_staff[i].checked = val;
                }
            },

            //결재선 관련
            setLine: (irow) => {
                let err_msg = 0;
                pop.addLineStaff(irow);
                if(err_msg!='') {
                    toast.error(err_msg);
                }
            },
            addLineStaff : (staff) => {
                let check = true;
                let check_idx=0;
                for(let i=0;i<pop.line_staff.length;i++) {
                    if(pop.line_staff[i].idx == staff.idx) {
                        check = false;
                        check_idx=i;
                    }
                }
                if(check) {
                    pop.line_staff.push(staff);
                }else{
                  pop.line_staff.splice(check_idx, 1);
                }
            },

            delLineStaff: (idx) => {
              for(let i=0;i<pop.in_staff.length;i++) {
                if(pop.in_staff[i].idx ==pop.line_staff[idx].idx) {
                  pop.in_staff[i].checked = false;
                }
              }
              pop.line_staff.splice(idx, 1);
                //pop.in_staff

            },

            // 참조자 관련
            delCCStaff: (idx) => {
                pop.cc_staff.splice(idx, 1);
            },

            addCCStaff : (staff) => {
                let check = true;
                for(let i=0;i<pop.cc_staff.length;i++) {
                    if(pop.cc_staff[i].idx == staff.idx) {
                        check = false;
                    }
                }
                if(check) {
                    pop.cc_staff.push(staff);
                }
            },

            setCC: () => {
                let err_msg = 0;
                for(let i=0;i<pop.in_staff.length;i++) {
                    if(pop.in_staff[i].checked == true) {
                        pop.addCCStaff(pop.in_staff[i]);
                    }
                }
                if(err_msg!='') {
                    toast.error(err_msg);
                }
            },

            loadApprovalLine : (info) => {
                pop.line_staff = [];
                pop.cc_staff = [];
                pop.line_staff = info.line;
                pop.cc_staff = info.cc;
                pop.showApprovalLineLoad=false;
                pop.getAdmin();
            },
            
            saveLine: () => {
                if(pop.line_title.trim().length==0) {
                    toast.error("결재선명을 입력해주세요.");
                    return;
                }
                if(pop.line_staff.length==0) {
                    toast.error("결재자를 선택해주세요..");
                    return;
                }

                pop.setAdmin();

                let params = {
                    title : pop.line_title,
                    line : pop.line_staff,
                    cc: pop.cc_staff
                };

                axios.post("/rest/documents/saveLine", params).then((res) => {
                    if( res.data.err == 0 ){
                        toast.success("결재선이 저장되었습니다.");
                    } else {
                        console.log(res.data.err_msg);
                    }
                });

            },

            setOffice : (office) => {
                pop.checkAll = false;
                pop.active_office = 0;
                pop.idx_office = office.idx;
                if(office.has_children=='N') {
                    pop.active_office = office.idx;
                    pop.setTeam({idx_office:office.idx, idx:0, deptname:office.deptname});
                }
            },
            setOffice2 : (idx) => {
              pop.checkAll = false;
              pop.active_office = 0;
              pop.idx_office = idx;
              pop.active_office = idx;
              pop.setTeam({idx_office:idx, idx:0, deptname:""});

            },

            teamSearch : () => {
                let params = {
                    tgdate : new Date(),
                };
                axios.get('/rest/documents/getTeamTree', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.teams = res.data.tree;
                        pop.staff = res.data.staff;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            setTeam: (team_info) => {
                pop.checkAll = false;
                pop.in_staff = [];
                pop.idx_team = team_info.idx;
                pop.deptname = team_info.deptname;
                for(let i=0;i<pop.staff.length;i++) {
                    if(pop.staff[i].idx_office == team_info.idx_office && (pop.staff[i].idx_team==0 || pop.staff[i].idx_team==team_info.idx)) {
                        pop.in_staff.push(pop.staff[i]);
                    }
                }
                for(let i=0;i<pop.in_staff.length;i++) {
                    pop.in_staff[i].checked = false;
                }
            },
            getAdmin : () => {
                for(let i=0;i<pop.line_staff.length;i++) {
                    if(pop.line_staff[i].is_admin == true) {
                        pop.idx_admin = pop.line_staff[i].idx;
                    }
                }
            },

            setAdmin : () => {
                for(let i=0;i<pop.line_staff.length;i++) {
                    pop.line_staff[i].is_admin = false;
                    if(pop.line_staff[i].idx == pop.idx_admin) {
                        pop.line_staff[i].is_admin = true;
                    }
                }
            },

            sendStaff : () => {
                if(pop.line_staff.length==0) {
                    toast.error("처리자를 선택해주세요.");
                    return;
                }
                
                pop.setAdmin();

                emit('selected', {line_staff : pop.line_staff, cc_staff: pop.cc_staff});
            },
          doSearch: () => {
            let params = {
              idx_team  : pop.idx_team,
              idx_office  : pop.idx_office,
              state:'DUTY',
              page:1,
              rows:300

            };
            for(let i=0;i<pop.in_staff.length;i++) {
              for(let j=0;j<pop.line_staff.length;j++) {
                if (pop.in_staff[i].idx == pop.line_staff[j].idx) {
                  pop.in_staff[i].checked = true;
                  console.log('test1');
                }
              }
            }
            axios.get("/rest/mypims/getHQList", { params : params }).then((res) => {
              if( res.data.err == 0 ){
                pop.in_staff  = res.data.list;
                pop.total = res.data.total;
                pop.info  = res.data.info;

              } else {
                console.info(res.data.err_msg);
              }
            });
          },

            onClose: () => {
                emit('close');
            }
        });

        onMounted(() => {
            // Mounted
          console.log(sessionStorage.getItem('taskText'));
            pop.teamSearch();
        });

        return {pop,store};
    }
}
</script>
<style lang="scss" scoped>
    .group-hide {
        display: none;
    }
    .scrollbox {
        overflow: hidden;
        overflow-y: auto;
    }
    .check-list {
        overflow: hidden;
        overflow-y: auto;
        max-height: 573px;
    }
    .active {
        color: #ff854a;
    }

    .sub-wrap .con-wrap .table-col thead {
        border-top: 0;
    }
    .sub-wrap .con-wrap .table-col thead tr th {
        padding: 0;
        height: 0;
        border-top: 2px solid #000;
    }
    .sub-wrap .con-wrap .table-col tbody tr td {
        padding: 0;
    }
</style>